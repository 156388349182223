import { MainAxisAlignment } from "layout/flex";
import { Padding } from "layout/layout";
import Row from "layout/row";
import { ReactComponent as MedocanLogo } from "assets/svg/medocan-logo.svg";
import "./header.scss";

const Header = () => {
	return (
		<Row
			as="header"
			className="header-component"
			mainAxisAlignment={MainAxisAlignment.Start}
			padding={Padding.Large}>
			<a href="/">
				<MedocanLogo />
			</a>
		</Row>
	);
};

export default Header;
