import { diceCoefficient } from "dice-coefficient";
import { useAuth } from "pages/admin/admin-page";
import React from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";

// TODO: Upgrade to new react router to use native nested routes
// TODO: Find out how to type as flat Routes while preserving key types.
const flatten = (routes: Routes, root: string = ""): Routes =>
	Object.fromEntries(
		Object.entries(routes).flatMap(([key, route]) => [
			[key, { ...route, path: `${root}/${route.path}` }],
			...(route.subroutes
				? Object.entries(flatten(route.subroutes, `/${route.path}`))
				: []),
		])
	);

type Routes = Record<string, Route>;

type Route = Readonly<{
	path: string;
	component: React.LazyExoticComponent<any>;
	authorised?: boolean;
	subroutes?: Routes;
}>;

/** Define your routes as an entry in this object. Types should be self-explanatory. */
export const routes = flatten({
	contact: {
		path: "/contact-accountability",
		component: React.lazy(() => import("pages/contact-page/contact-page")),
	},
	disclaimer: {
		path: "/disclaimer",
		component: React.lazy(
			() => import("pages/disclaimer-page/disclaimer-page")
		),
	},
	privacyStatement: {
		path: "/privacy-statement",
		component: React.lazy(
			() => import("pages/privacy-statement-page/privacy-statement-page")
		),
	},
	home: {
		path: "/",
		component: React.lazy(() => import("pages/experts-page/experts-page")),
	},
	areas: {
		path: "/areas",
		component: React.lazy(() => import("pages/area-page/area-page")),
	},
	bodyparts: {
		path: "/bodyparts/:areaId/",
		component: React.lazy(() => import("pages/bodypart-page/bodypart-page")),
	},
	conditions: {
		path: "/conditions/:areaId/:bodyPartId",
		component: React.lazy(
			() => import("pages/conditions-page/conditions-page")
		),
	},
	overview: {
		path: "/overview/:areaId/:bodyPartId/:conditionId",
		component: React.lazy(() => import("pages/overview-page/overview-page")),
	},
	video: {
		path: "/video/:areaId/:bodyPartId/:conditionId/:subTopicId/:contentId",
		component: React.lazy(() => import("pages/video-page/video-page")),
	},
	admin: {
		component: React.lazy(() => import("pages/admin/admin-page")),
		path: "admin",
		subroutes: {
			"admin/content/speakers": {
				path: "content/speakers",
				component: React.lazy(
					() => import("pages/admin/content/speakers/admin-content-speakers")
				),
				authorised: true,
			},
			"admin/content/categories": {
				path: "content/categories",
				component: React.lazy(
					() => import("pages/admin/content/categories/admin-content-wrapper")
				),
				authorised: true,
			},
			"admin/content/videos": {
				path: "content/videos",
				component: React.lazy(
					() => import("pages/admin/content/videos/admin-content-videos")
				),
				authorised: true,
			},
		},
	},
});

const NotFoundPage = React.lazy(() => import("pages/not-found/not-found-page"));

export const Routes = () => {
	const { auth } = useAuth();

	return (
		<RouterRoutes>
			{Object.entries(routes)
				.filter(([key, route]) => {
					if (!route.authorised || (route.authorised && auth)) {
						return [key, route];
					}
				})
				.map(([key, route]) => (
					<Route key={key} path={route.path} element={<route.component />} />
				))}
			<Route path="*" element={<NotFoundPage />} />
		</RouterRoutes>
	);
};

/** Get a route by its key, to prevent typos and to get
 * type errors when a route changes.
 */
export const route = (
	// TODO: Find out how to get a union of all keys instead of `string`.
	key: keyof typeof routes,
	params?: Record<string, { toString(...args: any[]): string }>
) => {
	if (!routes[key]) {
		const alternatives = Object.keys(routes)
			.map(route => [route, diceCoefficient(route, key)])
			.filter(([, dice]) => dice >= 0.66)
			.map(([route]) => route)
			.slice(0, 3)
			.join("\n\t- ");
		throw new Error(
			`Unknown route "${key}".` +
				(alternatives.length
					? ` Maybe you meant one of the following:\n\t- ${alternatives}`
					: "")
		);
	}

	return routes[key].path.replace(
		/:[^\/]+/g,
		match => params?.[match.slice(1)]?.toString() ?? match
	);
};
