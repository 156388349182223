import Flex, { FlexDirection, MainAxisAlignment } from "layout/flex";
import { classes, Gap, Padding } from "layout/layout";
import Row from "layout/row";
import "lib/i18n";
import Prefetch from "lib/prefetch";
import { Routes } from "lib/router";
import useWindowSize from "lib/window-size";
import ErrorPage from "pages/error/error-page";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import Header from "components/header/header";
import "./index.scss";
import "./assets/style/core-styling.scss";
import AdminSidebar from "components/admin/admin-sidebar/admin-sidebar";
import usePersistence, { Persistence } from "lib/persistence";
import { Trans, useTranslation } from "react-i18next";

const App = () => {
	const isAdminPage = useLocation().pathname.includes("/admin");
	const isSmallScreen = useWindowSize() === "small";
	const [_, setLang] = useAppLanguage();
	const { t } = useTranslation();

	const pageClassName = window.location.pathname
		.split("/")
		.filter(string => string)[0];

	if (pageClassName) {
		document.body.className = pageClassName;
	}

	useEffect(() => {
		setLang("nl");
	}, []);

	return (
		<>
			{isAdminPage ? (
				<Row>
					<AdminSidebar />
					<Flex as="main" grow padding={Padding.Huge} className="admin">
						<Routes />
					</Flex>
				</Row>
			) : (
				<>
					{isSmallScreen ? undefined : <Header />}
					<Flex
						as="main"
						grow
						className={classes([
							isSmallScreen ? "small" : "big",
							pageClassName,
						])}
						padding={isSmallScreen ? { vertical: Padding.Large } : undefined}>
						<Routes />
					</Flex>

					<Flex
						direction={isSmallScreen ? FlexDirection.Column : FlexDirection.Row}
						as="footer"
						className="footer"
						mainAxisAlignment={MainAxisAlignment.End}
						padding={Padding.Medium}
						gap={isSmallScreen ? Gap.Tiny : Gap.Large}>
						<a href="/contact-accountability">
							{t("pages.general.accountabilityContact")}
						</a>
						<a href="/disclaimer">{t("pages.general.disclaimer")}</a>
						<a href="/privacy-statement">
							{t("pages.general.privacyStatement")}
						</a>
						<span>
							<Trans
								i18nKey="pages.general.vector"
								components={{
									freepik: (
										<a
											href="https://www.flaticon.com/authors/freepik"
											target="_blank"
											rel="noopener noreferrer"></a>
									),
									iconHome: (
										<a
											href="https://www.flaticon.com/authors/icon-home"
											target="_blank"
											rel="noopener noreferrer"></a>
									),
									flaticon: (
										<a
											href="https://www.flaticon.com"
											target="_blank"
											rel="noopener noreferrer"></a>
									),
								}}
							/>
						</span>
					</Flex>
				</>
			)}
		</>
	);
};

const useAppLanguage = () => {
	const { i18n } = useTranslation();
	const [lang, setLang] = usePersistence("lang", i18n.resolvedLanguage);

	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	return [lang, setLang] as const;
};

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Persistence>
				<Prefetch
					loading={<span>Loading...</span>}
					error={error => <ErrorPage error={error} />}>
					<App />
				</Prefetch>
			</Persistence>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
