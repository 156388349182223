import { DateTime } from "luxon";
import Endpoints from "models/endpoints";
import { useAuth } from "pages/admin/admin-page";

type GetRequestParams = Readonly<{
	url: string;
	method: "GET" | "DELETE";
	decode: keyof typeof decoders;
	authorization?: string;
}>;

type DataRequest<T> = Readonly<{
	url: string;
	method: Exclude<keyof Endpoints, GetRequestParams["method"]>;
	body: T;
	encode: keyof typeof encoders;
	decode: keyof typeof decoders;
	authorization?: string;
}>;

const isGetRequest = <T>(
	request: RequestParams<T>
): request is GetRequestParams => ["GET", "DELETE"].includes(request.method);

type RequestParams<T> = GetRequestParams | DataRequest<T>;

const supportedMimeTypes = {
	json: "application/json",
	formData: "multipart/form-data",
};

const request = async <I extends {}, O>(params: RequestParams<I>) => {
	const headers: Record<string, string> = {
		Accept: supportedMimeTypes[params.decode],
	};

	if (params.authorization) {
		headers["Authorization"] = `Bearer ${params.authorization}`;
	}

	const options: RequestInit = {
		method: params.method,
		headers,
	};

	if (!isGetRequest(params)) {
		const encoder = encoders[params.encode];
		options.body = encoder(params.body);

		if (params.encode !== "formData") {
			headers["Content-Type"] = supportedMimeTypes[params.encode];
		}
	}

	options.headers = headers;

	const response = await fetch(params.url, options);

	if (!response.ok) throw response;
	if (response.status === 204) return Promise.resolve<O>(undefined as O);
	return decoders[params.decode](response) as Promise<O>;
};

const encoders = {
	json: (data: Record<any, any>) => JSON.stringify(data),
	formData: (data: Record<any, any>) => {
		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) => formData.set(key, value));
		return formData;
	},
};

const decoders = {
	json: <T>(data: Response) => data.json() as Promise<T>,
};

type RequestOptions = Readonly<{
	encode?: keyof typeof encoders;
	decode?: keyof typeof decoders;
	authorization?: string;
}>;

export const get = <URL extends keyof Endpoints["GET"]>(
	url: URL,
	options?: RequestOptions
): Promise<Endpoints["GET"][URL]> =>
	request({
		url,
		method: "GET",
		decode: options?.decode ?? "json",
		authorization: options?.authorization,
	});

export const post = <
	URL extends keyof Endpoints["POST"],
	T extends Endpoints["POST"][URL]
>(
	url: URL,
	body: T["input"],
	options?: RequestOptions
): Promise<T["ouput"]> =>
	request({
		url,
		body,
		method: "POST",
		encode: options?.encode ?? "json",
		decode: options?.decode ?? "json",
		authorization: options?.authorization,
	});

export const put = <
	URL extends keyof Endpoints["PUT"],
	T extends Endpoints["PUT"][URL]
>(
	url: URL,
	body: T["input"],
	options?: RequestOptions
): Promise<T["ouput"]> =>
	request({
		url,
		body,
		method: "PUT",
		encode: options?.encode ?? "json",
		decode: options?.decode ?? "json",
		authorization: options?.authorization,
	});

export const patch = <
	URL extends keyof Endpoints["PATCH"],
	T extends Endpoints["PATCH"][URL]
>(
	url: URL,
	body: T["input"],
	options?: RequestOptions
): Promise<T["ouput"]> =>
	request({
		url,
		body,
		method: "PATCH",
		encode: options?.encode ?? "json",
		decode: options?.decode ?? "json",
		authorization: options?.authorization,
	});

export const _delete = <URL extends keyof Endpoints["DELETE"]>(
	url: URL,
	options?: RequestOptions
): Promise<Endpoints["DELETE"][URL]> =>
	request({
		url,
		method: "DELETE",
		decode: options?.decode ?? "json",
		authorization: options?.authorization,
	});

let refreshPromise:
	| Promise<NonNullable<ReturnType<typeof useAuth>["auth"]>>
	| undefined;

const useNetwork = () => {
	const { auth, refresh } = useAuth();

	const refreshAuthIfNecessary = async () => {
		if (auth) {
			if (DateTime.fromISO(auth.expiresAt) < DateTime.now()) {
				await (refreshPromise ??= refresh(auth).finally(() => {
					refreshPromise = undefined;
				}));
			}
		}
	};

	const _get: typeof get = async (url, options) => {
		await refreshAuthIfNecessary();

		return get(url, { ...options, authorization: auth?.accessToken });
	};

	const _post: typeof post = async (url, body, options) => {
		await refreshAuthIfNecessary();

		return post(url, body, {
			...options,
			authorization: auth?.accessToken,
		});
	};

	const _put: typeof put = async (url, body, options) => {
		await refreshAuthIfNecessary();
		return put(url, body, {
			...options,
			authorization: auth?.accessToken,
		});
	};

	const _patch: typeof patch = async (url, body, options) => {
		await refreshAuthIfNecessary();
		return patch(url, body, {
			...options,
			authorization: auth?.accessToken,
		});
	};

	const __delete: typeof _delete = async (url, options) => {
		await refreshAuthIfNecessary();

		return _delete(url, {
			...options,
			authorization: auth?.accessToken,
		});
	};

	return {
		get: _get,
		post: _post,
		put: _put,
		patch: _patch,
		_delete: __delete,
	};
};

export default useNetwork;
