import Column from "layout/column";
import { ReactNode } from "react";
import "./admin-fieldset.scss";

type AdminFieldSetProps = {
	title: string;
	children: ReactNode;
};

const Fieldset = ({ title, children }: AdminFieldSetProps) => {
	return (
		<fieldset className="admin-fieldset">
			<div>
				<legend>{title}</legend>
				{children}
			</div>
		</fieldset>
	);
};

export default Fieldset;
