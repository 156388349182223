import { FormEvent, ReactNode } from "react";
import "./admin-form.scss";

type AdminFormProps = {
	onSubmit: (e: FormEvent<HTMLFormElement>) => void;
	children: ReactNode;
};

const Form = ({ onSubmit, children }: AdminFormProps) => {
	return (
		<form
			className="admin-form"
			onSubmit={e => {
				e.preventDefault();
				onSubmit(e);
			}}>
			{children}
		</form>
	);
};

export default Form;
