import { ReactNode } from "react";
import "./error-message.scss";

type ErrorMessageProps = {
	children: ReactNode;
};

const ErrorMessage = ({ children }: ErrorMessageProps) => {
	return (
		<p className="error-message" role="alert">
			{children}
		</p>
	);
};

export default ErrorMessage;
