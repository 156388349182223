import Button from "components/admin/admin-button/admin-button";
import Fieldset from "components/admin/admin-fieldset/admin-fieldset";
import Form from "components/admin/admin-form/admin-form";
import AdminPasswordInput from "components/admin/inputs/password/admin-password-input";
import AdminTextInput from "components/admin/inputs/text/admin-text-input";
import { t } from "i18next";
import Column from "layout/column";
import { MainAxisAlignment } from "layout/flex";
import Row from "layout/row";
import makeUseAuthentication from "lib/authentication";
import { post } from "lib/network";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import "./admin-page.scss";

export const useAuth = makeUseAuthentication({
	login: (username, password) =>
		post("/api/Oauth2", {
			grantType: "Password",
			clientId: "05004bd2-18d9-402f-9a1b-673fcf1d46e7",
			username: username,
			password: password,
		}).then(
			auth =>
				[
					{
						accessToken: auth.accessToken,
						refreshToken: auth.refreshToken,
						roles: auth.roles,
						username: username,
						expiresAt: DateTime.now()
							.plus({ milliseconds: auth.expiresIn })
							.toISO(),
					},
					{ id: "05004bd2-18d9-402f-9a1b-673fcf1d46e7" },
				] as const
		),
	logout: () => Promise.resolve(),
	refresh: auth =>
		post("/api/Oauth2", {
			grantType: "RefreshToken",
			clientId: "05004bd2-18d9-402f-9a1b-673fcf1d46e7",
			refreshToken: auth.refreshToken,
			username: auth.username,
		}).then(result => ({
			accessToken: result.accessToken,
			refreshToken: result.refreshToken,
			roles: result.roles,
			username: auth.username,
			expiresAt: DateTime.now()
				.plus({ milliseconds: result.expiresIn })
				.toISO(),
		})),
});

const AdminPage = () => {
	const { auth, login } = useAuth();
	const { handleSubmit, control, formState, setValue, reset, getValues } =
		useForm({
			defaultValues: {
				username: "",
				password: "",
			},
			reValidateMode: "onChange",
		});

	const onSubmit = async ({
		username,
		password,
	}: {
		username: string;
		password: string;
	}) => {
		await login(username, password);
	};

	return (
		<Column className="admin-page">
			{!auth && (
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Fieldset title={t("pages.adminPage.login.title")}>
						<Controller
							name="username"
							control={control}
							rules={{
								required: true,
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: { isTouched, isDirty, error },
							}) => (
								<AdminTextInput
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									inputRef={ref}
									name={name}
									label={t("pages.adminPage.login.username")}
									error={error}
									isTouched={isTouched}
									isDirty={isDirty}
								/>
							)}
						/>
						<Controller
							name="password"
							control={control}
							rules={{
								required: true,
							}}
							render={({
								field: { onChange, onBlur, value, name, ref },
								fieldState: { isTouched, isDirty, error },
							}) => (
								<AdminPasswordInput
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									inputRef={ref}
									name={name}
									label={t("pages.adminPage.login.password")}
									error={error}
									isTouched={isTouched}
									isDirty={isDirty}
								/>
							)}
						/>
					</Fieldset>
					<Row mainAxisAlignment={MainAxisAlignment.End}>
						<Button
							value={t("pages.adminPage.login.login")}
							theme={"primary"}
						/>
					</Row>
				</Form>
			)}
		</Column>
	);
};

export default AdminPage;
