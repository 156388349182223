import { classes, Gap } from "layout/layout";
import Row from "layout/row";
// import { ReactComponent as ChevronIcon } from "assets/icons/chevron-icon.svg";
import { ReactComponent as AddIcon } from "assets/svg/add.svg";
import "./admin-button.scss";
import { CrossAxisAlignment } from "layout/flex";

type ButtonProps = {
	value: string | JSX.Element;
	onClick?: () => void;
	type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
	showAddIcon?: boolean;
	IconLeft?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	theme?: "basic" | "primary" | "destructive";
	disabled?: boolean;
};

const Button = (props: ButtonProps) => {
	const { value, onClick, IconLeft, theme, disabled, showAddIcon, type } =
		props;

	return (
		<button
			className={classes(["button", theme ?? "basic"])}
			onClick={onClick}
			disabled={disabled}
			type={type}>
			<Row gap={Gap.Tiny} crossAxisAlignment={CrossAxisAlignment.Center}>
				{IconLeft || showAddIcon ? (
					<>
						{IconLeft && <IconLeft />}
						{showAddIcon && <AddIcon />}
					</>
				) : undefined}
				{value}
			</Row>
		</button>
	);
};
export default Button;
