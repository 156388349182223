import "./admin-label.scss";

type AdminLabelProps = {
	htmlFor: string;
	label: string;
};

const AdminLabel = ({ htmlFor, label }: AdminLabelProps) => (
	<label htmlFor={htmlFor} className="admin-label">
		{label}
	</label>
);

export default AdminLabel;
