import Column from "layout/column";
import "./admin-sidebar.scss";
import { ReactComponent as MedocanLogo } from "assets/svg/medocan-logo-blue.svg";
import { ReactComponent as AnalyseSvg } from "assets/svg/analyse.svg";
import { ReactComponent as ContentSvg } from "assets/svg/content.svg";
import { ReactComponent as LogoutSvg } from "assets/svg/logout.svg";
import { classes, Gap, Padding } from "layout/layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Row from "layout/row";
import { CrossAxisAlignment } from "layout/flex";
import { useAuth } from "pages/admin/admin-page";

const AdminSidebar = () => {
	const location = useLocation();
	const { t } = useTranslation();
	const { auth, logout } = useAuth();

	const topics = [
		// {
		// 	title: "Analyse",
		// 	pathname: "/admin/analyse",
		// 	icon: AnalyseSvg,
		// 	subtopics: [],
		// },
		{
			title: t("pages.adminPage.sidebar.content.title"),
			pathname: "/admin/content",
			icon: ContentSvg,
			subtopics: [
				{
					subtitle: t("pages.adminPage.sidebar.content.subcategories.speakers"),
					subpathname: "/admin/content/speakers",
				},
				{
					subtitle: t(
						"pages.adminPage.sidebar.content.subcategories.categories"
					),
					subpathname: "/admin/content/categories",
				},
				{
					subtitle: t("pages.adminPage.sidebar.content.subcategories.videos"),
					subpathname: "/admin/content/videos",
				},
			],
		},
	];

	const findMatchingTopic = topics.find(({ pathname }) =>
		location.pathname.includes(pathname)
	);

	const [openedTopics, setOpenedTopics] = useState(
		findMatchingTopic?.pathname ?? topics[0].pathname
	);
	const [openedSubTopics, setOpenedSubTopics] = useState(
		findMatchingTopic && findMatchingTopic.subtopics.length
			? findMatchingTopic.subtopics.find(({ subpathname }) =>
					location.pathname.includes(subpathname)
			  )?.subpathname ?? findMatchingTopic?.subtopics[0].subpathname
			: ""
	);

	return (
		<Column
			className="admin-sidebar"
			padding={{
				left: Padding.Huge,
				vertical: Padding.Medium,
				right: Padding.Large,
			}}
			gap={Gap.Large}>
			<a href="/admin" className="medocan-logo">
				<MedocanLogo />
			</a>
			<Column gap={Gap.Small}>
				{auth && (
					<>
						{topics.map(subject => {
							return (
								<Column key={subject.pathname}>
									<button
										className={classes([
											"admin-sidebar-mainbutton",
											openedTopics === subject.pathname && "active",
										])}
										onClick={() => {
											setOpenedTopics(subject.pathname);
										}}>
										<Row
											padding={Padding.Medium}
											gap={Gap.Small}
											crossAxisAlignment={CrossAxisAlignment.Center}>
											{<subject.icon />}
											<p>{subject.title}</p>
										</Row>
									</button>
									<Column
										gap={Gap.Medium}
										padding={{ top: Padding.Medium, left: Padding.Large }}>
										{openedTopics === subject.pathname
											? subject.subtopics.map((sub, index) => {
													return (
														<a
															key={index}
															href={sub.subpathname}
															className={classes([
																"admin-sidebar-subbutton",
																sub.subpathname === openedSubTopics && "active",
															])}
															onClick={() =>
																setOpenedSubTopics(sub.subpathname)
															}>
															{sub.subtitle}
														</a>
													);
											  })
											: undefined}
									</Column>
								</Column>
							);
						})}
						<button
							className={classes(["admin-sidebar-mainbutton"])}
							onClick={logout}>
							<Row
								padding={Padding.Medium}
								gap={Gap.Small}
								crossAxisAlignment={CrossAxisAlignment.Center}>
								<LogoutSvg />
								<p>{t("pages.adminPage.sidebar.logout")}</p>
							</Row>
						</button>
					</>
				)}
			</Column>
		</Column>
	);
};

export default AdminSidebar;
