import Column from "layout/column";
import { classes, Gap } from "layout/layout";
import { useState } from "react";
import { FieldError, RefCallBack } from "react-hook-form";
import AdminLabel from "../../label/admin-label";
import "./admin-password-input.scss";
import ErrorMessage from "../error/error-message";

type AdminTextInputProps = {
	value: any;
	onChange: (...event: any[]) => void;
	onBlur: () => void;
	inputRef: RefCallBack;
	label: string;
	name: string;
	error?: FieldError;
	isTouched: boolean;
	isDirty: boolean;
	disabled?: boolean;
};

const AdminPasswordInput = (props: AdminTextInputProps) => {
	const { value, onChange, onBlur, inputRef, name, label, error, disabled } =
		props;
	const [inputValue, setInputValue] = useState(value);

	return (
		<Column gap={Gap.Small}>
			<AdminLabel htmlFor={name} label={label} />
			<input
				className={classes(["admin-password-input", error && "error"])}
				type="password"
				ref={inputRef}
				value={inputValue}
				onChange={e => {
					onChange(e);
					setInputValue(e.currentTarget.value);
				}}
				onBlur={onBlur}
				id={name}
				disabled={disabled}
			/>
			{error && <ErrorMessage>{error.message}</ErrorMessage>}
		</Column>
	);
};

export default AdminPasswordInput;
